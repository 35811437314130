import { type SuluMedia } from '@/types/Sulu';

export default function useApiImage(apiUrl: string) {
  const getApiImageThumbnail = (image: SuluMedia, format = 'fullhd'): string => {
    if (!image || Array.isArray(image) || !image.formatUri || !image.formatPreferredExtension) {
      return '';
    }

    // Local images should not be parsed
    if (!image.formatUri.startsWith('/uploads')) {
      return image.formatUri.replace('{extension}', image.formatPreferredExtension);
    }

    // SVGs can't be formatted, so take the original url
    const path = image.mimeType === 'image/svg+xml' || image.mimeType === 'image/svg'
      ? image.url
      : image.formatUri.replace('{format}', format).replace('{extension}', image.formatPreferredExtension);
    // Remove query parameters (alternative for: https://github.com/nuxt/image/pull/997)
    return `${apiUrl}${decodeURIComponent(path)}`.split('?')[0];
    // Note: this url does not work correctly with the srcset attribute in NuxtImg,
    // but if you escape the spaces with %20, the main image src does not work for
    // the static generated site (problem in Nitro)
  };

  const getAlt = (image: SuluMedia): string => image?.title || '';

  const getFocusPoint = (image: SuluMedia): string => {
    const positionXMap = ['left', 'center', 'right'];
    const positionYMap = ['top', 'center', 'bottom'];
    if (!image || ![0, 1, 2].includes(image.focusPointX)) {
      return 'center center';
    }
    const { focusPointX: x, focusPointY: y } = image;
    return `${positionXMap[x]} ${positionYMap[y]}`;
  };

  return {
    getApiImageThumbnail,
    getAlt,
    getFocusPoint,
  };
}

import { MetaConfig } from 'vue-meta';
import { useRoute, useRuntimeConfig } from 'nuxt/app';
import { type SuluExtension, type SuluMedia } from '@/types/Sulu';

export default function useSeo(apiUrl: string) {
  function getOgImage(extension: SuluExtension, fallbackImage?: SuluMedia): string | undefined {
    let image: string | undefined;
    const imagesize = 'fullhd';
    const { getApiImageThumbnail } = useApiImage(apiUrl);
    if (extension.excerpt.images.length > 0) {
      image = getApiImageThumbnail(extension.excerpt.images[0], imagesize);
    } else if (fallbackImage) {
      image = getApiImageThumbnail(fallbackImage, imagesize);
    }
    return image;
  }

  const head = (extension: SuluExtension, fallback: any = {}): MetaConfig => {
    const data: any = {
      title: fallback.title,
      meta: [],
      link: [],
    };

    if (extension === undefined) {
      return data;
    }

    const { seo } = extension;
    if (seo.title) {
      data.title = seo.title;
      data.meta.push({
        name: 'title',
        property: 'og:title',
        hid: 'title',
        content: seo.title,
      });
    }
    if (seo.description) {
      data.meta.push({
        name: 'description',
        hid: 'description',
        content: seo.description,
      });
      data.meta.push({
        name: 'description',
        property: 'og:description',
        hid: 'og:description',
        content: seo.description,
      });
    }
    const img = getOgImage(extension, fallback.image);
    if (img) {
      data.meta.push({
        property: 'og:image',
        hid: 'og:image',
        content: img,
      });
    }
    if (seo.noIndex) {
      data.meta.push({ name: 'robots', content: 'noindex' });
      data.meta.push({ name: 'googlebot', content: 'noindex' });
    }

    let canonicalUrl = fallback.canonicalUrl ?? seo.canonicalUrl;
    const config = useRuntimeConfig();
    if (!canonicalUrl && config.public.domains.canonical) {
      const route = useRoute();
      canonicalUrl = config.public.domains.canonical + route.path;
    }
    if (canonicalUrl) {
      data.link.push({ rel: 'canonical', content: canonicalUrl });
    }

    return data;
  };

  const convertStringListToArray = (text: string) => {
    if (!process.client) {
      return [];
    }

    const div = document.createElement('div');
    div.innerHTML = text;

    return Array.from(div.getElementsByTagName('li')).map((element) => element.textContent);
  };

  const convertCookTimeToISO = (prepTime: string): string => {
    let minutes = prepTime.substring(0, prepTime.indexOf(' ')); // converts "10 minutes" to "10"

    const days = Math.floor(minutes / 1440);
    minutes -= days * 1440;
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    let duration = 'PT';
    if (days > 0) {
      duration += `${days}D`;
    }
    if (hours > 0) {
      duration += `${hours}H`;
    }
    duration += `${minutes}M`;
    return duration;
  };

  return { head, convertStringListToArray, convertCookTimeToISO };
}
